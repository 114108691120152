import React from "react";
import "./Socials.css";
import { Col, Container, Row } from "react-bootstrap";
import { IoLocationSharp, IoMailSharp, IoCallSharp } from "react-icons/io5";

const Socials = () => {
  return (
    <section id="socials" className="socials-container">
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="d-flex justify-content-center align-items-center">
          <Col>
            <a href="https://goo.gl/maps/3MLvNQMLDb1C1q287" target="_blank">
              <IoLocationSharp size="40" color="rgb(162, 0, 0)" />
            </a>
          </Col>
          <Col>
            <a href="mailto:hello@mymedicresources.com.my" target="_blank">
              <IoMailSharp size="40" color="rgb(162, 0, 0)" />
            </a>
          </Col>
          <Col>
            <a href="tel:+60389585514">
              <IoCallSharp size="40" color="rgb(162, 0, 0)" />
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Socials;
