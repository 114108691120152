import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import "./Header.css";
import MMRLogo from "../../assets/images/mmr-logo.png";
import { NavLink as Link, useLocation } from "react-router-dom";
import Pic5 from "../../assets/images/Pic5.jpg";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const changeBackground = () => {
    if (window.scrollY > 111) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
  });

  const backgroundImageBasedOnPath = () => {
    if (location.pathname === "/" || location.pathname === "/products") {
      return "";
    } else if (location.pathname === "/services-and-repair" && !isScrolled) {
      return `url(${Pic5})`;
    } else {
      return "none";
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      className={!isScrolled ? "my-container" : "my-container-scrolled"}
      fixed={isScrolled ? "top" : "none"}
      style={{ backgroundImage: backgroundImageBasedOnPath() }}
      // style={{ marginTop: !isScrolled ? 111 : 0 }}
      // fixed="top"
    >
      <Container>
        <Navbar.Brand href="" onClick={() => window.scrollTo(0, 0)}>
          <img src={MMRLogo} width="200px" height="auto" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          {/* <Nav className="me-auto">
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav> */}
          <Nav activeKey={location.pathname}>
            <Nav.Link>
              <Link
                to="/"
                className={({ isActive }) =>
                  isActive ? "nav-links-active" : "nav-links"
                }
              >
                Home
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/about-us"
                className={({ isActive }) =>
                  isActive ? "nav-links-active" : "nav-links"
                }
              >
                About Us
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/products"
                className={({ isActive }) =>
                  isActive ? "nav-links-active" : "nav-links"
                }
              >
                Products
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/services-and-repair"
                className={({ isActive }) =>
                  isActive ? "nav-links-active" : "nav-links"
                }
              >
                Services & Repair
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/contact-us"
                className={({ isActive }) =>
                  isActive ? "nav-links-active" : "nav-links"
                }
              >
                Contact Us
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
