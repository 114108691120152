import React from "react";
import ContactUsContent from "../components/ContactUsContent/ContactUsContent";
import Socials from "../components/socials/Socials";

const ContactUs = () => {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <ContactUsContent />
      <Socials />
    </div>
  );
};

export default ContactUs;
