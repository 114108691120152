import { Card, Col, Container, Image, Row } from "react-bootstrap";
import "./index.css";
import EMSLogo from "../../../../assets/images/ems-logo.png";
import SeesheenLogo from "../../../../assets/images/seesheen-logo.png";

export default function ProductsList() {
  return (
    <div className="products-list-container">
      <Container>
        <Col style={{ paddingBottom: 50, paddingTop: 50 }}>
          <Image src={EMSLogo} height={46} />
        </Col>
        <Row>
          <Col lg="6" md="12">
            <Card
              className="card-clickable"
              onClick={() =>
                window.open(
                  "https://www.ems-urology.com/products/laserclast-thulium-power",
                  "_blank"
                )
              }
            >
              <Image
                src={
                  "https://www.ems-urology.com/sites/urology/files/styles/product_teaser/public/2021-10/LaserClast_Thulium_Perspective.jpg?itok=8-JXJXgt"
                }
                style={{
                  width: "50%",
                }}
              />
              <h5 style={{ position: "absolute", bottom: 0 }}>
                LaserClast Thulium Power
              </h5>
            </Card>
          </Col>
          <Col lg="6" md="12">
            <Card
              className="card-clickable"
              onClick={() =>
                window.open(
                  "https://www.ems-urology.com/products/laserclast-35",
                  "_blank"
                )
              }
            >
              <Image
                src={
                  "https://www.ems-urology.com/sites/urology/files/styles/product_teaser/public/2021-10/EMS_Laserclast_35_Front.png?itok=dK_r5z9T"
                }
                style={{ width: "70%" }}
              />
              <h5 style={{ position: "absolute", bottom: 0 }}>LaserClast 35</h5>
            </Card>
          </Col>
        </Row>
        <Col style={{ paddingBottom: 50, paddingTop: 50 }}>
          <Image src={SeesheenLogo} height={46} />
        </Col>
        <Row>
          <Col xs="12">
            <Card
              className="card-clickable"
              onClick={() =>
                window.open("http://www.seesheen.com/product/12/", "_blank")
              }
            >
              <Image
                src={require("../../../../assets/images/seesheen-product.png")}
              />
              <h5 style={{ position: "absolute", bottom: 0 }}>
                Flexible Video Ureterorenoscope
              </h5>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
