import React from "react";

const ErrorPage = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h5>Error, page not found! :(</h5>
    </div>
  );
};

export default ErrorPage;
