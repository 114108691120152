import React from "react";
import { Container } from "react-bootstrap";
import "./AboutContent.css";

const AboutContent = () => {
  return (
    <div className="main-wrapper">
      <Container>
        <div className="title-wrapper">
          <h5>About Us</h5>
        </div>
        <div className="description-wrapper">
          <p>
            <span className="text-red">My Medic Resources (MMR)</span> is a
            leading Bumiputera-owned company that was established on March 28th,
            2007. We specialize in the distribution of Medical Equipment &
            Disposables, renting laser systems for urology departments, and
            repairing flexible endoscopes, rigid endoscopes, and telescopes. Our
            company is supported by both foreign and local principals, who
            provide technical expertise, after-sales support, and maintenance
            activities for all of our products.
          </p>
          <p>
            <span className="text-red">We</span> are a registered vendor with
            the Ministry of Finance, Malaysia and have earned BUMIPUTERA status.
            Additionally, we hold Good Distributor Practice of Medical Device
            (GDPMD) status and are registered with the Medical Device Authority
            of Malaysia (MDA).
          </p>
          <p>
            <span className="text-red">MMR</span> also obtained Good Distributor
            Practice of Medical Device (GDPMD) status and registered with
            Medical Device Authority of Malaysia (MDA).
          </p>
        </div>
        <div className="title-wrapper">
          <h5>Vision</h5>
        </div>
        <div className="description-wrapper">
          <p>
            <span className="text-red">Our</span> vision is to continuously
            create healthy and valuable products that meet our clients' needs.
            We are committed to developing our products to the highest level of
            quality and ensuring excellent customer satisfaction through our
            after-sales services.
          </p>
        </div>
        <div className="title-wrapper">
          <h5>Mission</h5>
        </div>
        <div className="description-wrapper">
          <p>
            At <span className="text-red">MMR</span>, our mission is to become a
            progressive and dynamic growth-oriented company with a long-term
            vision of becoming a regional leader in the marketing of a wide
            range of medical, scientific, and technical products and services.
            As part of our long-term strategy, we aim to expand our operations
            offshore, focusing on select Asian countries. With a focus on
            quality and customer satisfaction, MMR is dedicated to providing the
            highest level of service to our clients.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default AboutContent;
