import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <Container>
        <p className="text-center pt-2">
          My Medic Resources © All rights reserved 2022
        </p>
      </Container>
    </div>
  );
};

export default Footer;
