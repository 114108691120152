import React from "react";
import { Container } from "react-bootstrap";
import "./index.css";

export default function ServiceAndRepairContent() {
  return (
    <div className="main-wrapper">
      <Container>
        <div className="title-wrapper">
          <h5>
            Our Services & Repair: Offering Cost-effective Solutions for Medical
            Equipment and Disposables
          </h5>
        </div>
        <div className="description-wrapper">
          <p>
            At our company, we understand that repairs can be costly and
            inefficient, especially when redundant parts are quoted based on
            assembly models. Such wastage of resources inevitably leads to high
            repair costs for healthcare facilities.
          </p>
          <p>
            To address this issue, our team of highly skilled engineers offers
            an alternative approach by providing customized quotes and repair
            methods for damaged endoscopes. We prioritize the replacement of
            necessary parts without compromising on repair quality. Our
            expertise extends to the medium repair and servicing of various
            endoscope types, including:
          </p>
          <p>
            <li>• Gastroscope</li>
            <br />
            <li>• Colonoscopes</li>
            <br />
            <li>• Duodenoscope</li>
            <br />
            <li>• Bronchoscope</li>
            <br />
            <li>• Cystoscope</li>
            <br />
            <li>• Ureteroscope</li>
            <br />
            <li>• ENT Scope</li>
            <br />
            <li>• Telescope (all types)</li>
            <br />
          </p>
          <p>
            Our customer base for these services and products encompasses
            government hospitals, private hospitals, universities, and research
            centers. We have established a significant presence within the
            government sector, actively participating in tender exercises to
            fulfill their requirements.
          </p>
          <p>
            By choosing our company, you can expect professional repairs,
            efficient resource management, and unparalleled quality in endoscope
            servicing. We are committed to serving the healthcare industry and
            optimizing the repair process for healthcare facilities.
          </p>
        </div>
      </Container>
    </div>
  );
}
