import "./global.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/index";
import ContactUs from "./pages/ContactUs";
import About from "./pages/about-us";
import ErrorPage from "./pages/ErrorPage";
import TopLine from "./components/top-line/TopLine";
import InfoBoard from "./components/info-board/InfoBoard";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Products from "./pages/products/index";
import ServiceAndRepair from "./pages/service-and-repair";

function App() {
  return (
    <Router>
      <TopLine />
      <InfoBoard />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services-and-repair" element={<ServiceAndRepair />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
