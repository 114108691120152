import React from "react";
import "./index.css";
import ProductsList from "./components/products-list";

const Products = () => {
  return (
    <section
      style={{ marginBottom: 60, maxWidth: "100vw", overflow: "hidden" }}
    >
      <div className="products-page-container" />
      <div className="products-page-text">
        <h1>Products</h1>
      </div>
      <ProductsList />
    </section>
  );
};

export default Products;
