import Carousel from "react-multi-carousel";
import "./index.css";
import FirstImage from "../../../../assets/images/carousel-image-1.png";
import SecondImage from "../../../../assets/images/carousel-image-2.png";
import ThirdImage from "../../../../assets/images/carousel-image-3.png";
import FourthImage from "../../../../assets/images/carousel-image-4.png";
import FifthImage from "../../../../assets/images/carousel-image-5.png";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

const items = [
  {
    id: 1,
    image: FirstImage,
    title: "Slide 1",
    caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    image: SecondImage,
    title: "Slide 2",
    caption: "Praesent nec massa id nunc ultrices malesuada.",
  },
  {
    id: 3,
    image: ThirdImage,
    title: "Slide 3",
    caption: "Sed ultricies mauris sit amet velit ultricies tempor.",
  },
  {
    id: 4,
    image: FourthImage,
    title: "Slide 4",
    caption: "Nulla at neque ac ligula vestibulum fringilla.",
  },
  {
    id: 5,
    image: FifthImage,
    title: "Slide 5",
    caption: "Sed ultricies mauris sit amet velit ultricies tempor.",
  },
  {
    id: 6,
    image: FirstImage,
    title: "Slide 6",
    caption: "Nulla at neque ac ligula vestibulum fringilla.",
  },
];

export default function ProductCarousel() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={2000}
      showDots={true}
      containerClass="carousel-container"
      swipeable={false}
      ssr={true} // means to render carousel on server-side.
      draggable={true}
      infinite={true}
    >
      {items.map((item) => (
        <div
          className="text-center"
          key={item.id}
          style={{
            display: "flex",
            border: "1px solid #e5e5e5",
            height: "500px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/">
            <img
              alt=""
              className="img-fluid pe-none user-select-none"
              style={{}}
              src={item.image}
            />
          </Link>
        </div>
      ))}
    </Carousel>
  );
}
