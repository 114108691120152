import { useEffect, useState } from "react";
import {
  Card,
  Container,
  Stack,
  Button,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import LocationImage from "../../../../assets/images/location.png";
import "./index.css";

export default function Location() {
  return (
    <Container className="products-container" fluid>
      <div className="products-text-container">
        <h2>Our Location</h2>
      </div>
      <div>
        <Row
          style={{
            justifyContent: "center",
            width: "99vw",
            marginBottom: "50px",
            marginTop: "50px",
          }}
        >
          <Image
            src={LocationImage}
            className="responsive-image"
            onClick={() => {
              window.open("https://goo.gl/maps/SAkozMi2LZsDVvWw8", "_blank");
            }}
          />
        </Row>
      </div>
    </Container>
  );
}
