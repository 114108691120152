import { useEffect, useState } from "react";
import { Card, Container, Stack, Button, Row, Col } from "react-bootstrap";
import "../products/index.css";
import FirstImage from "../../../../assets/images/carousel-image-1.png";
import SecondImage from "../../../../assets/images/carousel-image-2.png";
import ThirdImage from "../../../../assets/images/carousel-image-3.png";
import FourthImage from "../../../../assets/images/carousel-image-4.png";
import FifthImage from "../../../../assets/images/carousel-image-5.png";
import ProductCarousel from "../carousel";
import { Link } from "react-router-dom";

const items = [
  {
    id: 1,
    image: FirstImage,
    title: "Slide 1",
    caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    image: SecondImage,
    title: "Slide 2",
    caption: "Praesent nec massa id nunc ultrices malesuada.",
  },
  {
    id: 3,
    image: ThirdImage,
    title: "Slide 3",
    caption: "Sed ultricies mauris sit amet velit ultricies tempor.",
  },
  {
    id: 4,
    image: FourthImage,
    title: "Slide 4",
    caption: "Nulla at neque ac ligula vestibulum fringilla.",
  },
  {
    id: 5,
    image: FifthImage,
    title: "Slide 5",
    caption: "Sed ultricies mauris sit amet velit ultricies tempor.",
  },
  {
    id: 6,
    image: FirstImage,
    title: "Slide 6",
    caption: "Nulla at neque ac ligula vestibulum fringilla.",
  },
];

export default function Products() {
  const [itemsPerSlide, setItemsPerSlide] = useState(3);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setItemsPerSlide(1);
    } else {
      setItemsPerSlide(3);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // call the handleResize function to initialize the itemsPerSlide state
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Container className="products-container" fluid>
      <div className="products-text-container">
        <h2>Top Products</h2>
      </div>
      <div>
        {/* <Row className="products-carousel-container">
          <Col>
            <Carousel interval={null} indicators={false}>
              {items.map((item, index) => {
                if (index % itemsPerSlide === 0) {
                  return (
                    <Carousel.Item key={item.id}>
                      <Row>
                        {items
                          .slice(index, index + itemsPerSlide)
                          .map((subItem) => (
                            <Col key={subItem.id}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <img
                                  className="d-block w-100"
                                  src={subItem.image}
                                  alt={subItem.title}
                                />
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </Carousel.Item>
                  );
                }
                return null;
              })}
            </Carousel>
          </Col>
        </Row> */}
        <Row className="products-carousel-container">
          <ProductCarousel />
        </Row>
        <Row
          style={{
            justifyContent: "center",
            width: "99.7vw",
            marginBottom: "50px",
          }}
        >
          <Link
            style={{
              display: "flex",
              backgroundColor: "#A20000",
              border: "none",
              borderRadius: "4px",
              color: "#FFFFFF",
              width: "200px",
              height: "50px",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
            to={"/products"}
          >
            <span>View Products</span>
          </Link>
        </Row>
      </div>
    </Container>
  );
}
