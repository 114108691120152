import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./TopLine.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const TopLine = () => {
  return (
    <div className="top-line-container">
      <Container>
        <Row>
          <Col className="text-right d-flex justify-content-end top-line-responsive">
            <ul>
              <li>
                <Link to="/contact-us">
                  <a>
                    <BsFillTelephoneFill size={14} color={"#fff"} />
                  </a>
                </Link>
              </li>
              <li>
                <a
                  href="mailto:hello@mymedicresources.com.my"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiFillMail size={14} color={"#fff"} />
                </a>
              </li>
              <li>
                <a
                  href="https://goo.gl/maps/3MLvNQMLDb1C1q287"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaMapMarkerAlt size={14} color={"#fff"} />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopLine;
