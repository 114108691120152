import React from "react";
import Body from "../../components/body/Body";
import Socials from "../../components/socials/Socials";
import Products from "./components/products";
import Location from "./components/location";

export default function Home() {
  const location = {
    address: "My Medic Resources",
    lat: 2.974500801686721,
    lng: 101.67760597116434,
  };
  return (
    <>
      <Body />
      {/* <ContactUs /> */}
      <Products />
      <Location />
      <Socials />
      {/* <Map location={location} zoomLevel={17} /> */}
      {/* <Divider /> */}
    </>
  );
}
