import React from "react";
import AboutContent from "../../components/AboutContent/AboutContent";
import Socials from "../../components/socials/Socials";

const About = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <AboutContent />
      <Socials />
    </div>
  );
};

export default About;
