import React from "react";
import "./Body.css";

const Body = () => {
  return (
    <section>
      <div className="body-container" />
      <div className="my-text">
        <h1>MY MEDIC RESOURCES</h1>
      </div>
    </section>
  );
};

const styles = {};

export default Body;
