import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./ContactUsContent.css";
import emailjs from "emailjs-com";

const ContactUsContent = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target);
    emailjs
      .sendForm(
        "service_o5sgc1c",
        "template_313lbeb",
        e.target,
        "IlxLWCv35ShFrqzws"
      )
      .then((res) => {
        console.log(res);
        alert("Your message has been sent. Thank you!");
      });
  };
  return (
    <div className="contact-us-container">
      <Container className="pt-5">
        <Row className="justify-content-center">
          <Col></Col>
          <Col md="6">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  name="name"
                  placeholder="Enter name"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="title"
                  name="title"
                  placeholder="Enter title"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  type="message"
                  placeholder="Enter message"
                  as="textarea"
                  name="message"
                />
              </Form.Group>
              <Button className="btn-custom" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUsContent;
