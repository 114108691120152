import React from "react";
import ServiceAndRepairContent from "./components/Content";
import Socials from "../../components/socials/Socials";
import "./index.css";

export default function ServiceAndRepair() {
  return (
    <section style={{ maxWidth: "100vw", overflow: "hidden" }}>
      <div className="services-page-container" />
      <div className="services-page-text">
        <h1>Services & Repair</h1>
      </div>
      <ServiceAndRepairContent />
      <Socials />
    </section>
  );
}
