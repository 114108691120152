import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./InfoBoard.css";
import { BiPhoneCall, BiTimeFive } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { BsPinMap } from "react-icons/bs";
import { Link } from "react-router-dom";

const InfoBoard = () => {
  return (
    <div className="info-board-container">
      <Container>
        <Row className="p-2">
          <Col className="info-icon-box d-flex">
            <div className="info-icon me-1">
              <BiPhoneCall size={20} />
            </div>
            <div className="info-icon-details">
              <h6 className="icon-title">Call Us</h6>
              <span className="icon-desc">
                <a href="tel:+60389585514">
                  <a>+603-8958 5514</a>
                </a>
              </span>
            </div>
          </Col>
          <Col className="info-icon-box d-flex">
            <div className="info-icon me-1">
              <AiOutlineMail size={20} />
            </div>
            <div className="info-icon-details">
              <h6 className="icon-title">Reach our Email</h6>
              <span className="icon-desc">
                <a
                  href="mailto:hello@mymedicresources.com.my"
                  target="_blank"
                  rel="noreferrer"
                >
                  hello@mymedicresources.com.my
                </a>
              </span>
            </div>
          </Col>
          <Col className="info-icon-box d-flex" md={4}>
            <div className="info-icon me-1">
              <BsPinMap size={20} />
            </div>
            <div className="info-icon-details">
              <h6 className="icon-title">Our Location</h6>
              <span className="icon-desc">
                <a
                  href="https://goo.gl/maps/3MLvNQMLDb1C1q287"
                  target="_blank"
                  rel="noreferrer"
                >
                  Taman Suria Tropika, 43300 Seri Kembangan, Selangor
                </a>
              </span>
            </div>
          </Col>
          <Col className="info-icon-box d-flex">
            <div className="info-icon me-1">
              <BiTimeFive size={20} />
            </div>
            <div className="info-icon-details">
              <h6 className="icon-title">Working Hours</h6>
              <span className="icon-desc">Mon - Fri / 9am - 5pm</span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InfoBoard;
